import { CalendarOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Input, message, Modal, Radio, Select, Tooltip } from 'antd';
import ptBR from "antd/lib/locale/pt_BR";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiTransferBankAccount } from '../../../api/transfer';
import { ReactComponent as Check } from '../../../assets/images/transfer/Check.svg';
import { ReactComponent as Clock } from '../../../assets/images/transfer/ClockCircle.svg';
import { ReactComponent as Ellipsis } from '../../../assets/images/transfer/Ellipsis.svg';
import { ReactComponent as Success } from '../../../assets/images/transfer/Success.svg';
import { bankChoices } from '../../../utils/bankChoices';
import { formatPrice, formatPriceInCents } from '../../../utils/format';
import './TransferModal.scss';

const TransferModal = ({ visible, onClose, bankBalance }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [priceTransfer, setPriceTransfer] = useState(0);
  const [errors, setErrors] = useState({});
  const [internalCurrentStep, setInternalCurrentStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [dayTransfer, setDayTransfer] = useState(null);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setCurrentStep(0);
      setInternalCurrentStep(0);
      setPriceTransfer(0);
    }
  }, [visible]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setPriceTransfer(parseInt(numericValue, 10) || 0);

    if (priceTransfer >= 500) {
      setErrors({ amount: null });
    }
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentStep < 1) {
          setCurrentStep(currentStep + 1);
        } else if (currentStep === 1 && internalCurrentStep === 0) {
          if (priceTransfer >= 500) {
            setCurrentStep(2);
            setInternalCurrentStep(0);
          } else {
            setErrors({ amount: "Por favor, insira um valor maior que R$ 5,00." });
          }
        } else if (currentStep === 2 && internalCurrentStep === 0) {
          setCurrentStep(3);
          setInternalCurrentStep(1);
        }
        else if (currentStep === 3 && internalCurrentStep === 1) {
          const payload = {
            receiver_name: form.getFieldValue('name'),
            receiver_tax_id: form.getFieldValue('cpfCnpj'),
            receiver_tax_id_type: form.getFieldValue('personType'),
            receiver_bank: JSON.parse(form.getFieldValue('bank'))?.id,
            receiver_agency: form.getFieldValue('agency'),
            receiver_account: form.getFieldValue('account'),
            receiver_account_type: form.getFieldValue('accountType'),
            target_date: form.getFieldValue('dateTransfer'),
            amount: priceTransfer
          }

          apiTransferBankAccount(payload)
            .then((response) => {
              setSuccessModal(true);
            })
            .catch((error) => {
              message.error("Erro ao realizar transferência. Verifique os dados e tente novamente.");
            });
        }
      })
      .catch((errorInfo) => {
        console.log('Erro na validação:', errorInfo);
      });
  };

  const handleConfirmOK = () => {
    message.info("Transferência cancelada com sucesso!");
    setShowModal(false);
    onClose();
  };

  const successModalOK = () => {
    setSuccessModal(false);
    onClose();
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(true);
  };

  const stepTitles = ['Destinatário', 'Valor', 'Finalizar saque'];

  const uniqueBankChoices = bankChoices
    .filter(
      (item, index, self) => self.findIndex(b => b.id === item.id) === index
    )
    .filter(bank => bank.id && bank.name);

  return (
    <>
      <Modal
        className="transfer-modal"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <h2 className="ant-modal-title">Transferir Saldo</h2>
        <div className="custom-steps">
          {stepTitles.map((title, index) => (
            <>
              <div className="step" key={index}>
                <div className={`step-icon ${currentStep > index ? 'step-finish' : currentStep === index ? 'step-active' : ''}`}>
                  {currentStep < index && <Ellipsis />}
                  {index === currentStep && <Clock />}
                  {currentStep > index && <Check />}
                </div>
                <span className={`step-title ${currentStep >= index ? 'step-active-title' : ''} ${currentStep > index ? 'step-finish' : ''}`}>
                  {title}
                </span>
              </div>
              {index < stepTitles.length - 1 && <div className="line" />}
            </>
          ))}
        </div>
        <div className="body-transfer">
          <Form form={form} layout="vertical" style={{ marginTop: 20 }} initialValues={{ personType: "PF" }}>
            {currentStep === 0 && (
              <>
                <div className="subtitle-body">
                  <h3>Para quem deseja transferir?</h3>
                </div>
                <Form.Item
                  name="name"
                  label="Nome completo"
                  rules={[{ required: true, message: 'Por favor, insira o nome completo!' }]}
                >
                  <Input />
                </Form.Item>
                <div className="two-body-inputs">
                  <Form.Item
                    name="cpfCnpj"
                    label={form.getFieldValue('personType') === "PJ" ? "CNPJ" : "CPF"}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const isPF = getFieldValue('personType') === "PF";
                          const requiredLength = isPF ? 11 : 14;
                          if (!value) {
                            return Promise.reject(
                              new Error(`Por favor, insira o ${isPF ? "CPF" : "CNPJ"}!`)
                            );
                          }
                          if (value.length !== requiredLength) {
                            return Promise.reject(
                              new Error(
                                `O ${isPF ? "CPF" : "CNPJ"} deve conter exatamente ${requiredLength} dígitos.`
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      maxLength={form.getFieldValue('personType') === "PF" ? 11 : 14}
                      placeholder={`Digite seu ${form.getFieldValue('personType') === "PF" ? "CPF" : "CNPJ"}`}
                    />
                  </Form.Item>

                  <Form.Item
                    name="personType"
                    className="type-hidden"
                    label="Tipo de pessoa"
                    rules={[{ required: true, message: 'Por favor, selecione o tipo de pessoa!' }]}
                  >
                    <Radio.Group>
                      <Radio value="PF">Pessoa Física</Radio>
                      <Radio value="PJ" >Pessoa Jurídica</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div className="three-body-inputs">
                  <Form.Item
                    style={{ width: '100%' }}
                    name="bank"
                    label="Instituição"
                    rules={[{ required: true, message: 'Por favor, selecione uma instituição!' }]}
                  >
                    <Select
                      placeholder="Selecione o banco"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children?.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => form.setFieldsValue({ bank: value })}
                    >
                      {uniqueBankChoices.map((bank) => (
                        <Select.Option key={bank.id} value={JSON.stringify({ id: bank.id, name: bank.name })}>
                          {bank.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="agency"
                    label="Agência"
                    rules={[{ required: true, message: 'Por favor, insira a agência!' }]}
                  >
                    <Input placeholder="000" maxLength={4} />
                  </Form.Item>
                  <Form.Item
                    name="account"
                    label="Conta"
                    rules={[{ required: true, message: 'Por favor, insira o número da conta!' }]}
                  >
                    <Input placeholder="Com dígito" maxLength={10} />
                  </Form.Item>
                </div>
                <Form.Item
                  name="accountType"
                  label="Tipo de conta"
                  rules={[{ required: true, message: 'Por favor, selecione o tipo de conta!' }]}
                >
                  <Radio.Group>
                    <Radio value="SAVINGS_ACCOUNT">Conta Poupança</Radio>
                    <Radio value="CHECKING_ACCOUNT">Conta Corrente</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 1 && (
              <>
                <div className="subtitle-body">
                  <h3>Qual o valor da transferência/saque?</h3>
                  <span>Saldo disponível de R$ {formatPriceInCents(bankBalance)}</span>
                </div>
                <Form.Item
                  validateStatus={errors.amount ? 'error' : ''}
                  help={errors.amount}
                  label={
                    <span>
                      Digite o valor desejado{" "}
                      <Tooltip title="Você deverá ter esse saldo disponível no dia da transferência.">
                        <InfoCircleOutlined style={{ color: "#1890ff" }} />
                      </Tooltip>
                    </span>
                  }
                  style={{ width: '100%', maxWidth: 300 }}
                  rules={[{ required: true, message: 'Por favor, insira o valor!' }]}
                >
                  <Input
                    value={formatPrice(priceTransfer / 100)}
                    onChange={handleAmountChange}
                  />
                </Form.Item>
              </>
            )}
            {currentStep === 2 && internalCurrentStep === 0 && (
              <Form layout="vertical" form={form}>
                <Form.Item
                  label="Para quando deseja realizar essa transferência?"
                  name="transferOption"
                  rules={[{ required: true, message: "Por favor, selecione uma opção!" }]}
                >
                  <Radio.Group
                    className="transfer-option"
                    onChange={(e) => {
                      const option = e.target.value;
                      if (option === "agora") {
                        form.setFieldsValue({ dateTransfer: moment().format("YYYY-MM-DD") });
                      } else {
                        form.setFieldsValue({ dateTransfer: null });
                      }
                    }}
                  >
                    <Radio value="agora" onChange={() => setDayTransfer('agora')}>Agora - Receber em até 1 dia útil</Radio>
                    <Radio value="agendar" onChange={() => setDayTransfer('agendar')}>Agendar</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="dateTransfer"
                  label="Data de agendamento"
                  dependencies={['transferOption']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: form.getFieldValue('transferOption') === 'agendar',
                      message: 'Por favor, selecione uma data!',
                    }),
                  ]}
                >
                  <div className="date-picker-container">
                    <CalendarOutlined style={{ color: "#1890ff", fontSize: "20px" }} />
                    <DatePicker
                      format="MMM DD, YYYY"
                      placeholder="Jun, 10 2024"
                      locale={ptBR}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      suffixIcon={null}
                      allowClear={false}
                      disabled={dayTransfer === 'agora'}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = date.format("YYYY-MM-DD");
                          form.setFieldsValue({ dateTransfer: formattedDate });
                        }
                      }}
                    />
                  </div>
                </Form.Item>
                <Alert
                  message="Atenção: as transferências são feitas apenas em dias úteis, durante o horário comercial."
                  type="warning"
                  showIcon
                  style={{ marginTop: "10px" }}
                />
              </Form>
            )}

            {currentStep === 3 && internalCurrentStep === 1 && (
              <div className="resume">
                <h3>Confira as informações antes de finalizar</h3>
                <p className="subtitle">Realizando transferência para:</p>

                <div className="confirmation-card">

                  <div className="info-card">
                    <div className="info-row">
                      <span className="label">Valor</span>
                      <span className="value">{formatPrice(priceTransfer / 100)}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Nome</span>
                      <span className="value">{form.getFieldValue('name')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">{form.getFieldValue('personType') === "CPF" ? "CPF" : "CPNJ"}</span>
                      <span className="value">{form.getFieldValue('cpfCnpj')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Instituição</span>
                      <span className="value">{form.getFieldValue('bank') && JSON.parse(form.getFieldValue('bank'))?.name}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Agência</span>
                      <span className="value">{form.getFieldValue('agency')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Conta</span>
                      <span className="value">{form.getFieldValue('account')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Tipo de conta</span>
                      <span className="value">{form.getFieldValue('accountType') === 'poupanca' ? 'Conta Poupança' : 'Conta Corrente'}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Data de transferência</span>
                      <span className="value">
                        {form.getFieldValue('transferOption') === 'agora'
                          ? 'Agora - Receber em até 1 dia útil'
                          : form.getFieldValue('dateTransfer')
                            ? moment(form.getFieldValue('dateTransfer')).format('MMM DD, YYYY')
                            : 'Sem data agendada'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            )}
            <div className='container-buttons' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
              <Button style={{ marginRight: 8 }} onClick={handleCancel} danger>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleNext}>
                {currentStep <= 3 && internalCurrentStep === 0 ? 'Próximo' : 'Finalizar'}
              </Button>
            </div>
          </Form>
        </div>

      </Modal>
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined style={{ color: "orange", marginRight: "8px" }} />
            Atenção
          </span>
        }
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleConfirmOK}
          >
            Sim
          </Button>,
        ]}
        open={showModal}
        onCancel={() => setSuccessModal(false)}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <p>Tem certeza que deseja cancelar a solicitação de transferência?</p>
      </Modal>

      <Modal
        className='success-modal'
        width={600}
        title={
          <span className='title-modal'>
            Tudo certo! Saque solicitado
          </span>
        }
        footer={[
          <Button
            className='button-stop'
            key="submit"
            type="primary"
            onClick={successModalOK}
          >
            Fechar
          </Button>,
        ]}
        open={successModal}
        onCancel={handleCancelModal}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <div className='container-success'>
          <Success />
          <p>Pronto! Sua transferência foi solicitada com sucesso. </p>
          <p>O valor estará disponível em breve!</p>
        </div>
      </Modal>
    </>
  );
};

export default TransferModal;
