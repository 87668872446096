
export const accountEntryKindChoices = [
  { value: 'IN', label: 'Entrada' },
  { value: 'OUT', label: 'Saída' }
];

export const accountEntryStatusChoices = [
  { value: 'PLANNED', label: 'Planejado' },
  { value: 'SCHEDULED', label: 'Agendado' },
  { value: 'PAID', label: 'Pago' },
  { value: 'WAITING_CONFIRMATION', label: 'Confirmando' },
  { value: 'PREDICTED', label: 'Previsto' },
  { value: 'REVERSED', label: 'Estornado' },
  { value: 'CANCELED', label: 'Cancelado' },
  { value: 'FAILED', label: 'Falhou' }
];