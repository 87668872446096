import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import {Breadcrumb, Input, Layout, Select, Table, theme, Tooltip} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { apiGetVirtualAccountDetails, apiListVirtualAccountEntries } from "../../../api/account";
import { ReactComponent as Money } from "../../../assets/images/account/money.svg";
import { ReactComponent as MoneyDown } from "../../../assets/images/account/table-money-down.svg";
import { ReactComponent as MoneyUp } from "../../../assets/images/account/table-money-up.svg";
import { formatDate, formatPriceInCents } from "../../../utils/format";
import { accountEntryStatusChoices } from "../../../utils/optionsEvent";
import { useIsMobileHook } from "../../../utils/WindowHooks";
import TransferModal from "../../common/TransferModal/TransferModal";
import TransferModalBoleto from "../../common/TransferModalBoleto/TransferModalBoleto";
import './Account.scss';

const Account = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [habilityBalance, setHabilityBalance] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openTransferBoletoModal, setOpenTransferBoletoModal] = useState(false);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleSortChange = (value) => {
    setSortedInfo({
      order: value,
      columnKey: 'value',
    });
  };

  const filteredData = transactions?.results?.filter((item) =>
    item.name.toLowerCase().includes(searchText?.toLowerCase())
  );



  const columns = [
    {
      title: 'Nome da transação',
      dataIndex: 'name',
      key: 'name',
      render: (transactionName, { kind }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {kind === 'OUT' ? <MoneyDown style={{ marginRight: 8 }} /> : <MoneyUp style={{ marginRight: 8 }} />}
          <span>{transactionName}</span>
        </div>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => {
        const amountA = parseFloat(a.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
        const amountB = parseFloat(b.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
        return amountA - amountB;
      },
      render: (amount) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{formatPriceInCents(amount)}</span>
        </div>
      ),
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      responsive: ["md"],
    },
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (created_at) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{formatDate(created_at)}</span>
        </div>
      ),
      responsive: ["md"],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, item) => {
        const statusObj = accountEntryStatusChoices.find(choice => choice.value === status);
        return <Tooltip placement="topLeft" title={item.status_reason}>
          {statusObj ? (
            <span className={`status-color ${statusObj.label}`}>{statusObj.label}</span>
          ) : (
            <span className="status-color unknown">Desconhecido</span>
          )}
        </Tooltip>
      },
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const isMobile = useIsMobileHook(1100);
  const handleRowClick = (record) => {
    const id = record.key;
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== id));
    } else {
      setExpandedRowKeys([id]);
    }
  };

  const expandedRowRender = (record) => (
    <div className="expanded">
      <div className="ticket-actions">
        <p><strong>Nome da transação:</strong> {record.transactionName}</p>
        <p><strong>Valor:</strong> {record.value}</p>
        <p><strong>Data:</strong> {record.date}</p>
        <p><strong>Status:</strong> <span className={`status-color ${record.status}`} >{record.status}</span></p>
      </div>
    </div>
  );

  const [dataAccount, setDataAccount] = useState({
    balance: null,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [accountDetails, entries] = await Promise.all([
          apiGetVirtualAccountDetails(),
          apiListVirtualAccountEntries(),
        ]);

        setDataAccount({ balance: accountDetails.balance });
        setTransactions(entries);
      } catch (err) {
        console.error('Erro ao buscar dados:', err);
        setError('Erro ao carregar os dados. Tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [openTransferModal, openTransferBoletoModal])

  return (
    <div className='Account' style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <title>Clap Tickets - Conta</title>
      </Helmet>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Content
          className="content-dashboard"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Breadcrumb
            className="breadcrumb-title"
          >
            <Breadcrumb.Item >Minha conta</Breadcrumb.Item>
          </Breadcrumb>

          <div className="container-account">
            <div className="top-account">
              <div className="card-balance">
                <span>Saldo da conta</span>
                <div className="value-balance">
                  <div className="balance-container">
                    {habilityBalance && !loading ? <p className={`money ${habilityBalance ? "" : "hidden"}`}>{formatPriceInCents(dataAccount?.balance)}</p> : <p className="empty-money">••••</p>}
                    {/* {!habilityBalance && <p className="empty-money">••••</p>} */}
                  </div>
                  {!habilityBalance && <EyeOutlined onClick={() => setHabilityBalance(true)} />}
                  {habilityBalance && <EyeInvisibleOutlined onClick={() => setHabilityBalance(false)} />}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: 10 }}>
                <button className="money-transfer" onClick={() => setOpenTransferModal(true)}>
                  <Money /> Transferir saldo
                </button>
                <button className="money-transfer" onClick={() => setOpenTransferBoletoModal(true)}>
                  <Money /> Pagar Boleto
                </button>
              </div>
            </div>

            <div className="account-body">
              <div className="account-filters">
                <div className="title">
                  <h2>Histórico de movimentações</h2>
                </div>
                <div className="filters-div">
                  <Select
                    defaultValue="classificar por"
                    style={{ width: 200, marginBottom: 20 }}
                    onChange={handleSortChange}
                  >
                    <Select.Option value="ascend">Classificar por valor (crescente)</Select.Option>
                    <Select.Option value="descend">Classificar por valor (decrescente)</Select.Option>
                  </Select>
                  <Input.Search
                    placeholder="Pesquisar transação"
                    onSearch={handleSearch}
                    style={{ width: 300, marginBottom: 20 }}
                  />
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={filteredData}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50'],
                  showQuickJumper: true,
                }}
                loading={loading}
                rowKey="key"
                expandable={{
                  expandedRowRender: isMobile ? expandedRowRender : undefined,
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => handleRowClick(record),
                  responsive: ["xl"],
                }}
              />
            </div>
          </div>
        </Content>
      </Layout>
      <TransferModal visible={openTransferModal} onClose={() => setOpenTransferModal(false)} bankBalance={dataAccount?.balance} />
      <TransferModalBoleto visible={openTransferBoletoModal} onClose={() => setOpenTransferBoletoModal(false)} bankBalance={dataAccount?.balance} />
    </div>
  )
}

export default Account
